import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Divider, Grid, IconButton, makeStyles } from "@material-ui/core";

import { Loader } from "../loader";
import BasicInfo from "./BasicInfo";
import { clearReduxDataOfCurrentComponent, createDelegation, getDelegation, getMembersList } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    tabController: {
        "& .MuiTab-textColorInherit ": {
            minWidth: "0px"
        }
    },
    appBar: {
        boxShadow: "none !important"
    },
    ContactTabs: {
        marginTop: 'none !important',
        marginBottom: 'none !important',
        border: "3px solid #eeee",
    },
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
        border: "3px solid #eeee",
        marginLeft: '-41px !important'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 10,
    },
}))

let errorObj = {
    user: null,
    delegate: null,
    from: null,
    to: null
}
const dateFormat = 'yyyy-MM-DD';

const AddDelegation = (props) => {
    console.log('AddDelegation-props', props)
    const classes = useStyles();
    const [memberList, setMemberList] = useState([])
    const [availableDelegateMembers, setAvailableDeligateMembers] = useState([])
    const [delegationData, setDelegationData] = useState({
        // from: moment(new Date()).utc().format(dateFormat),
        // to: moment(new Date()).utc().format(dateFormat),
        from: moment(new Date()).format(dateFormat),
        to: moment(new Date()).format(dateFormat),
        assignPendingTasks: true
    })
    const [validationError, setValidationError] = useState(errorObj)

    let delegation = props.appConfiguration?.system?.delegations
    let hideDelegate = delegation?.hideDelegate || false

    useEffect(() => {
        if (props.match.params.id !== 'new') {
            fetchDelegationData()
            fetchData()
        } else {
            fetchData()
        }
    }, [])

    const fetchDelegationData = () => {
        Promise.all([
            props.getDelegation({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_delegation_data',
                dataModelId: props.data.chartData.selectedDataModel,
                memberId: props.member?._id,
                id: props.match.params.id
            })
        ].filter(Boolean))
    }

    const fetchData = () => {
        Promise.all([
            props.getMembersList({
                appId: props.appId,
                dataUniqueId: props.data?.chartUniqueId + '_member_list_data',
                orgId: props.user.orgId,
            })
        ].filter(Boolean))
    }

    // const fetchData =()=>{
    //     Promise.all([
    //         props.getRolesMembers({
    //             appId: props.appId,
    //             dataUniqueId:  props.data?.chartUniqueId+'_member_roles_data',
    //             orgId: props.user.orgId,
    //         }),
    //         props.getMembersList({
    //             appId: props.appId,
    //             dataUniqueId:  props.data?.chartUniqueId+'_member_list_data',
    //             orgId: props.user.orgId,
    //         })
    //     ].filter(Boolean))
    // }

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_member_list_data']?.length) {
            let members = props.formData.document[props.data?.chartUniqueId + '_member_list_data'].map(member => {
                return {
                    id: member._id,
                    name: member.name,
                    value: member._id,
                    label: member.name
                }
            });
            setMemberList(members);
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId + '_member_list_data']])

    useEffect(() => {
        if (props.formData?.document && props.formData.document[props.data?.chartUniqueId + '_delegation_data']) {
            let delegation = JSON.parse(JSON.stringify(props.formData.document[props.data?.chartUniqueId + '_delegation_data']))
            let obj = {
                "_id": delegation._id,
                user: delegation.user,
                delegate: delegation.delegate,
                from: delegation.from,
                to: delegation.to,
                assignPendingTasks: delegation.assignPendingTasks
            }

            if (obj.from) {
                if (obj.from.includes('T')) {
                    obj.from = moment(obj.from).utc().format(dateFormat)
                } else {
                    obj.from = moment(obj.from).format(dateFormat)
                }
            }
            if (obj.to) {
                if (obj.to.includes('T')) {
                    obj.to = moment(obj.to).utc().format(dateFormat)
                } else {
                    obj.to = moment(obj.to).format(dateFormat)
                }
            }

            setDelegationData(obj);
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId + '_delegation_data']])

    useEffect(() => {
        if (props.formData?.document && props.formData?.document[props.data?.chartUniqueId + '_create_delegation'] && (props.formData?.document[props.data?.chartUniqueId + '_create_delegation'].message || props.formData?.document[props.data?.chartUniqueId + '_create_delegation']._id)) {
            toast.success(props.formData?.document[props.data?.chartUniqueId + '_create_delegation']?.message);
            props.history.push({ pathname: `/${props.match.params.appid}/page/delegations` });
        }
    }, [props.formData && props.formData?.document[props.data?.chartUniqueId + '_create_delegation']])

    useEffect(() => {
        return () => {
            setMemberList([])
            setAvailableDeligateMembers([])
            clearCurrentChartReduxStore()
        }
    }, [])

    useEffect(() => {
        if (delegationData && delegationData.user?.id && memberList.length) {
            let dmems = memberList.filter(m => m.id !== delegationData.user.id);
            if (dmems && dmems.length) setAvailableDeligateMembers(dmems);
        }
    }, [delegationData.user])

    const clearCurrentChartReduxStore = () => {
        let subKeys = [
            props.data?.chartUniqueId + '_delegation_data',
            props.data?.chartUniqueId + '_create_delegation'
        ]

        props.clearReduxDataOfCurrentComponent({
            key: "document",
            subKey: subKeys
        })
    }

    const basicInfoOnFormChange = (key, value) => {
        if (validationError.user || validationError.delegate || validationError.from || validationError.to) {
            setValidationError(errorObj)
        }
        let obj = {
            ...delegationData,
            [key]: value
        }
        if (key === 'user') {
            obj['delegate'] = null
        }
        if (key === 'from' || key === 'to') {
            obj[key] = moment(obj[key]).format(dateFormat);
            // if (key === 'from') {
            //     obj['to'] = obj['from'];
            // }
        }
        setDelegationData(obj);
    }

    const basicInfoOnSubmit = (data) => {
        let _error = {
            user: true,
            delegate: hideDelegate ? null : true,
            from: true,
            to: true,
        }
        if (delegationData.user && delegationData.user.id && delegationData.user.name) {
            _error.user = null
        }
        if(hideDelegate){
            _error.delegate = null
        } else if (delegationData.delegate && delegationData.delegate.id && delegationData.delegate.name) {
            _error.delegate = null
        }
        if (delegationData.from) {
            _error.from = null
            delegationData.from = moment(delegationData.from).format(dateFormat)
        }
        if (delegationData.to) {
            _error.to = null
            delegationData.to = moment(delegationData.to).format(dateFormat)
        }

        if (!_error.user && !_error.delegate && !_error.from && !_error.to) {
            const payload = {
                data: delegationData,
                memberId: props.member?._id,
                "appId": props.appId,
                "orgId": props.user.orgId,
                dataModelId: props.data.chartData.selectedDataModel,
                dataUniqueId: props.data?.chartUniqueId + '_create_delegation',
            }
            props.createDelegation(payload)
        } else {
            setValidationError(_error)
        }
    }

    const onBack = () => {
        props.history.push({ pathname: `/${props.match.params.appid}/page/delegations` });
    }

    if (props.formData[props.data?.chartUniqueId + '_delegation_data_loading'] || props.formData[props.data?.chartUniqueId + '_create_delegation_loading']) {
        return <Loader />
    }

    return (
        <div>
            <div className={classes.header}>
                <IconButton onClick={onBack} title={'Back'}>
                    <ArrowBackIosIcon />
                </IconButton>
                <h1>{props.page.title}</h1>
            </div>
            <Divider />

            <Grid container style={{ marginTop: 20, }}>
                <BasicInfo
                    {...props}
                    memberList={memberList}
                    delegateMemberList={availableDelegateMembers}
                    delegationData={delegationData}
                    basicInfoOnFormChange={basicInfoOnFormChange}
                    validationError={validationError}
                    basicInfoOnSubmit={basicInfoOnSubmit}
                />
            </Grid>
        </div>);
};

const mapDispatchToProps = {
    getDelegation,
    getMembersList,
    createDelegation,
    clearReduxDataOfCurrentComponent
}
const mapStateToProps = state => {
    return {
        formData: state.form,
        member: state.appConfig.members,
        user: state.user,
        appConfiguration: state.form.document?.appConfig
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDelegation)))